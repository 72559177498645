










































import Vue from "vue";
import { mapGetters } from "vuex";
import { FETCH_OVERALL_ASSESSMENTS } from "@/store/modules/recruiter/constants";
import { AppAssessmentNames } from "@/store/modules/common/interfaces";
import {
  AssessmentVersions,
  DateFilterPayload
} from "@/store/modules/recruiter/interfaces";
import { TranslateResult } from "vue-i18n";
import SelectOptions from "@/components/recruiter/SelectOptions.vue";
import { SelectedFilters } from "@/interfaces/shared/selected_filters";
import AutoCompleteUserAndEidSearch from "@/components/shared/AutoCompleteUserAndEidSearch.vue";
import AssessmentsFilter from "@/components/recruiter/AssessmentsFilter.vue";
import { SearchUserObject } from "@/interfaces/shared/auto_complete_user_search";
import AssessmentsSelectedDatePicker from "@/components/recruiter/app_assessment/AssessmentsSelectedDatePicker.vue";

export default Vue.extend({
  name: "AppAssessmentListingFilterHeader",
  components: {
    SelectOptions,
    AssessmentsFilter,
    AssessmentsSelectedDatePicker,
    AutoCompleteUserAndEidSearch
  },
  data() {
    return {
      dates: [] as string[],
      english_placeholder: this.$t("auth.name") as TranslateResult,
      annotation_placeholder: this.$t("auth.name") as TranslateResult,
      pass_fail_filters: [
        { label: this.$t("assessments.both"), value: "both" },
        { label: this.$t("assessments.pass"), value: "pass" },
        { label: this.$t("assessments.fail"), value: "fail" }
      ] as SelectedFilters[],
      eid_name_filters: [
        { label: this.$t("auth.name"), value: this.$t("auth.name").toString() },
        { label: this.$t("auth.eid-number"), value: "eid-number" }
      ] as SelectedFilters[],
      version_filters: [
        { label: "Version 1", value: AssessmentVersions.VERSION_1 },
        { label: "Version 2", value: AssessmentVersions.VERSION_2 }
      ] as SelectedFilters[]
    };
  },
  computed: {
    AppAssessmentNames() {
      return AppAssessmentNames;
    },
    ...mapGetters("recruiter", {
      GetAssessments: FETCH_OVERALL_ASSESSMENTS
    }),
    PassFailAssessmentFilter(): string {
      return this.GetAssessments.score_filter;
    },
    AssessmentVersion(): string {
      return this.GetAssessments.assessment_version;
    },
    StatusAssessmentFilter(): string {
      return this.GetAssessments.status_filter;
    },
    SelectedDates(): string[] {
      return this.GetAssessments.selected_dates;
    },
    AverageFilter(): SelectedFilters[] {
      return this.GetAssessments.average_score_filter;
    },
    EnglishFilter(): SelectedFilters[] {
      return this.GetAssessments.english_assessment_filter;
    },
    AnnotationFilter(): SelectedFilters[] {
      return this.GetAssessments.annotation_assessment_filter;
    },
    EidNameFilter(): SelectedFilters[] {
      return this.GetAssessments.eid_name_filter;
    }
  },
  props: {
    version: {
      type: String
    },
    is_overall_result: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    searched_user(value: SearchUserObject[]) {
      this.GetAssessments.selected_candidates = value;
      this.GetAssessments.page = 1;
      this.$emit("filter_change");
    },
    on_selected_dates(dates: DateFilterPayload) {
      this.GetAssessments.selected_dates = dates;
      this.GetAssessments.page = 1;
      this.$emit("filter_change");
    },
    update_score_filter(filter: string) {
      this.GetAssessments.score_filter = filter;
      this.GetAssessments.page = 1;
      this.$emit("filter_change");
    },
    update_status_filter(filter: string) {
      this.$emit("update_status_filter", filter);
    },
    filter_change(filter: any) {
      this.GetAssessments.average_score_filter = filter.average_score;
      this.GetAssessments.english_assessment_filter = filter.english_score;
      this.GetAssessments.annotation_assessment_filter =
        filter.annotation_score;
      this.GetAssessments.assessment_version = filter.assessment_version;
      this.GetAssessments.page = 1;
      this.$emit("filter_change");
    },
    update_eid_name_filter(filter: string) {
      this.GetAssessments.eid_name_filter = filter;
    }
  }
});
