



























































































































































































































































































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import { SiteDirections } from "@/store/modules/common/interfaces";
import AppOverAllAssessmentListingFilterHeader from "@/components/recruiter/app_assessment/AppOverAllAssessmentListingFilterHeader.vue";
import { FETCH_OVERALL_ASSESSMENTS } from "@/store/modules/recruiter/constants";
import OnBoardingInvitationCard from "@/components/recruiter/OnBoardingInvitationCard.vue";
import {
  format_date,
  get_app_assessment_status,
  get_app_assessment_status_color,
  get_color_for_percentage,
  get_pass_or_fail_class,
  assessment_pass_fail_result,
  download_file
} from "@/utils/global";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import { get_color_by_name } from "@/utils/colors";
import { USER_DETAILS_BY_ID } from "@/store/modules/auth/constants";
import { User } from "@/interfaces/data_objects/user";
import RefreshButton from "@/components/shared/RefreshButton.vue";

export default Vue.extend({
  name: "AppOverallAssessmentReport",
  components: {
    AppOverAllAssessmentListingFilterHeader,
    OnBoardingInvitationCard,
    RefreshButton
  },
  data() {
    return {
      headers: [
        { text: "N.#", align: "start", value: "index" },
        { text: "Date", value: "date" },
        { text: "Candidate", value: "user" },
        {
          text: "English",
          value: "english_assessment",
          sub_text: "Assessment"
        },
        {
          text: "Annotation",
          value: "annotation_assessment",
          sub_text: "Assessment"
        },
        {
          text: "Assessments",
          value: "annotation_assessment1",
          sub_text: "Version 1 & 2"
        },
        { text: "Avg Score", value: "overall_result" }
      ],
      loading: false,
      per_page_options: [5, 10, 15, 20],
      download_csv_loading: false,
      on_boarding_invitation: false,
      user_object: {} as User,
      status: ""
    };
  },
  computed: {
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    ...mapGetters("recruiter", {
      overall_assessment_details: FETCH_OVERALL_ASSESSMENTS
    }),
    AppAssessments() {
      return AppAssessments;
    }
  },
  async mounted() {
    await this.process_assessment_listing();
  },
  methods: {
    format_date,
    get_app_assessment_status,
    get_app_assessment_status_color,
    get_color_for_percentage,
    get_pass_or_fail_class,
    assessment_pass_fail_result,
    ...mapActions("recruiter", {
      fetch_overall_assessments: FETCH_OVERALL_ASSESSMENTS
    }),
    ...mapActions("auth", {
      fetch_user_by_id: USER_DETAILS_BY_ID
    }),
    async process_assessment_listing() {
      this.loading = true;
      await this.fetch_overall_assessments(this.overall_assessment_details);
      this.loading = false;
    },
    get_percentage(result: AppAssessments.AnnotationAssessmentResult) {
      return Object.values(AppAssessments.AnnotationAssessmentRefs).reduce(
        (acc, key) => {
          if (result) {
            const part = result[key];
            if (part) {
              acc += part.score;
            }
            return acc;
          }
          return 0;
        },
        0
      );
    },
    reprocess_limit() {
      this.overall_assessment_details.page = 1;
      this.process_assessment_listing();
    },
    transform_view_icon(icon: HTMLElement): HTMLElement {
      const color = get_color_by_name("success");
      for (let node of icon.children) node.setAttribute("stroke", color);
      return icon;
    },
    view_assessment_result(
      assessment_result_id: number,
      user_id: number,
      assessment: string
    ) {
      this.$router.push(
        `/recruiter/assessments/${assessment}/${this.overall_assessment_details.assessment_version}/${assessment_result_id}/view/${user_id}`
      );
    },
    async export_csv() {
      this.download_csv_loading = true;
      const response = await this.fetch_overall_assessments({
        ...this.overall_assessment_details,
        download_report: true
      });
      if (response) {
        await download_file(response);
      }
      this.download_csv_loading = false;
    },
    async open_user_details(
      user: AppAssessments.AssessmentUserObject,
      status: AppAssessments.AppAssessmentStatus
    ) {
      const response = await this.fetch_user_by_id(user.id);
      if (response) {
        this.user_object = response;
        this.on_boarding_invitation = true;
        this.status = status;
      }
    }
  }
});
